document.addEventListener('DOMContentLoaded', function () {
    let questionBtn = document.querySelector('.main__question-block');
    let closeBtn = document.querySelector('.form-popup__close-btn');
    let formPopup = document.querySelector('.form-popup');
    const burgerMenu = document.querySelector('.header__menu-btn');

    questionBtn.addEventListener('click', () => {
        formPopup.classList.add('active');
        burgerMenu.style.zIndex = '0';
    });

    closeBtn.addEventListener('click', () => {
        formPopup.classList.remove('active');
        burgerMenu.style.zIndex = '5';
    });
});
