document.addEventListener('DOMContentLoaded', () => {
    const searchInput = document.querySelector('#direction-page__input');

    searchInput.addEventListener('input', (ev) => {
        const value = ev.target.value.trim();
        const directionItems = document.querySelectorAll('.directions__page-card');
        const searchRegExp = new RegExp(value, 'gi');

        if (value === '') {
            directionItems.forEach((el) => {
                el.classList.remove('active');
            });
            return;
        }

        directionItems.forEach((el) => {
            const innerCard = el.querySelector('.directions__card-title');
            const elementText = innerCard.textContent;
            const isContainsSearchRequest = searchRegExp.test(elementText);
            if (!isContainsSearchRequest) {
                el.classList.remove('active');
            } else {
                el.classList.add('active');
            }
        });
    });
});
