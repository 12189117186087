import Accordion from 'accordion-js';
import 'accordion-js/dist/accordion.min.css';
document.addEventListener('DOMContentLoaded', function () {
    new Accordion('.accordion-list', {
        duration: 300,
        elementClass: 'accordion-item',
        triggerClass: 'accordion-title',
        panelClass: 'accordion-desc',
    });
});
