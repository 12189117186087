document.addEventListener('DOMContentLoaded', () => {
    const tabSteps = document.querySelectorAll('.payment__steps-item');
    const stepsContent = document.querySelectorAll('.payment__preview-item');

    let i;
    let j;

    for (i = 0; i < tabSteps.length; i++) {
        tabSteps[i].addEventListener('mouseenter', function (e) {
            e.preventDefault();
            const activeTabAttr = e.target.getAttribute('data-step');

            for (j = 0; j < tabSteps.length; j++) {
                const contentAttr = stepsContent[j].getAttribute('data-preview-step');

                if (activeTabAttr === contentAttr) {
                    tabSteps[j].classList.add('active');
                    stepsContent[j].classList.add('active');
                } else {
                    tabSteps[j].classList.remove('active');
                    stepsContent[j].classList.remove('active');
                }
            }
        });
    }
});
