document.addEventListener('DOMContentLoaded', function () {
    let abiturDocumentBtn = document.querySelector('.abitur__documents-btn');
    let abiturDocumentContent = document.querySelectorAll('.abitur__documents-item');

    abiturDocumentBtn.classList.add('active');

    abiturDocumentBtn.addEventListener('click', () => {
        abiturDocumentContent.forEach((item) => {
            item.classList.add('active');
        });
    });
});
