document.addEventListener('DOMContentLoaded', function () {
    const blockWrapper = document.querySelectorAll('.university-programs__block-wrapper');
    const blockTitle = document.querySelectorAll('.university-programs__block');

    let i;
    for (i = 0; i < blockTitle.length; i++) {
        blockTitle[i].addEventListener('click', toggleItem, false);
    }

    function toggleItem() {
        const itemClass = this.parentNode.className;

        for (i = 0; i < blockWrapper.length; i++) {
            blockWrapper[i].className = 'university-programs__block-wrapper close';
        }
        if (itemClass === 'university-programs__block-wrapper close') {
            this.parentNode.className = 'university-programs__block-wrapper open';
        }
    }
});
