import {FormValidator} from './FormValidator';
import {FormSender} from './FormSender';
class CorruptionFeedback extends FormSender {
    #formSelector = 'form[name="question-form"]';
    #btnSelector = '.btn';
    #btnTextSelector = '.message-success';
    #btnLoadingSelector = 'btn--loading';
    #formErrorSelector = '.question-form__error';
    #requestUrl = '/api/v1/corruptionFeedback';
    #errorText = 'Извините, произошла ошибка. Обновите страницу или попробуйте позже.';

    constructor() {
        super();
        this.form = document.querySelector(this.#formSelector);
        this.btn = this.form?.querySelector(this.#btnSelector);
        this.formError = this.form?.querySelector(this.#formErrorSelector);
        this.initSendEvent();
    }

    initSendEvent() {
        this.form?.addEventListener('submit', this.send.bind(this));
    }

    send(e) {
        e.preventDefault();

        this.#cleanError();

        const validationResult = new FormValidator().validate(this.form);

        if (!validationResult.status) {
            this.#setError(validationResult.message);
            return;
        }

        const formData = new FormData(this.form);

        this.#setBtnLoading();

        this.formRequest(formData, this.#requestUrl)
            .then(() => {
                this.#setSuccessText('Ваше сообщение отправлено! ' + '\n' + 'Мы скоро свяжемся с вами!');
                this.#clearForm();
            })
            .catch((err) => alert(err || this.#errorText))
            .finally(() => {
                this.#removeBtnLoading();
                this.btn.blur();
            });
    }

    #setBtnLoading() {
        this.btn.classList.add(this.#btnLoadingSelector);
    }

    #removeBtnLoading() {
        this.btn.classList.remove(this.#btnLoadingSelector);
    }

    #cleanError() {
        this.formError.innerText = '';
    }

    #setError(message) {
        this.formError.innerText = message;
    }

    #clearForm() {
        const inputList = this.form.querySelectorAll('input');
        const textareaList = this.form.querySelectorAll('textarea');

        inputList.forEach((input) => (input.value = ''));
        textareaList.forEach((textarea) => (textarea.value = ''));
    }

    #setSuccessText(text) {
        document.querySelector(this.#btnTextSelector).innerText = text;
    }
}

export {CorruptionFeedback};
