import './pages/main.js';
import './components/menu.js';
import './components/cookie.js';
import './components/openMenuModal.js';
import './components/togglePaymentSteps.js';
import './components/changeActiveMenuLinks.js';
import './components/galleryCloseOpenBtn.js';
import './components/changeContentTabs.js';
import './components/changeFirstDocsItemLink.js';
import './components/openQuestionPopup.js';
import './components/sliders.js';
import './components/educationProgramAccordion.js';
import './components/accordion.js';
import './components/dormitoryTabs.js';
import './components/abiturDocumentsTab.js';
import './components/abiturDirectionTabs.js';
import './components/fancyGalleryNews.js';
import './components/searchDirections.js';
import './components/scrollFaqAnimation.js';
import './classes/FormValidator.js';
import './classes/FormSender.js';
import './classes/QuestionForm.js';
import './classes/CorruptionFeedback.js';
