import {FormValidator} from './FormValidator';
/* global BX:writable */
class FormSender extends FormValidator {
    async formRequest(formData, url) {
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'Session-Id': BX.bitrix_sessid(),
            },
        });

        const responseJson = await response.json();

        if (response.status !== 200) {
            throw new Error(responseJson.message);
        }

        return responseJson;
    }
}

export {FormSender};
