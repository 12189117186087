document.addEventListener('DOMContentLoaded', () => {
    const tabs = document.querySelectorAll('[data-dormitory-title]');
    const contents = document.querySelectorAll('[data-dormitory-content]');
    const tab = document.querySelector('[data-dormitory-title]');
    const content = document.querySelector('[data-dormitory-content]');

    tab.classList.add('active');
    content.classList.add('active');

    tabs.forEach((itemTab) => {
        itemTab.addEventListener('click', () => {
            tabs.forEach((itemTab) => itemTab.classList.remove('active'));
            itemTab.classList.add('active');

            contents.forEach((itemContent) => {
                if (itemContent.dataset.dormitoryContent === itemTab.dataset.dormitoryTitle) {
                    itemContent.classList.add('active');
                } else {
                    itemContent.classList.remove('active');
                }
            });
        });
    });
});
