document.addEventListener('DOMContentLoaded', () => {
    const mainMenu = document.querySelectorAll('.header__popup-item');

    mainMenu.forEach((item) => {
        let childList = item.querySelector('ul.menu__submenu');

        item.addEventListener('mouseover', () => {
            if (childList) {
                childList.classList.add('show');
            }
        });

        item.addEventListener('mouseout', () => {
            if (childList) {
                childList.classList.remove('show');
            }
        });
    });
});
