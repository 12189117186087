document.addEventListener('DOMContentLoaded', () => {
    const btnGallery = document.querySelector('.btn-gallery');
    const btnGalleryArrow = document.querySelector('.btn-arrow');
    const galleryHidden = document.querySelector('.news-detail__gallery-hidden');

    function hideGallery() {
        galleryHidden.classList.toggle('visible');
    }

    function changeBtnStyle() {
        btnGalleryArrow.classList.toggle('transform');
    }

    btnGallery.addEventListener('click', () => {
        hideGallery();
        changeBtnStyle();
    });
});
