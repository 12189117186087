import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

document.addEventListener('DOMContentLoaded', () => {
    //scroll-faq-animation
    gsap.registerPlugin(ScrollTrigger);
    gsap.utils.toArray('.parallax-up-400').forEach((section) => {
        gsap.to(section, {
            scrollTrigger: {
                trigger: section,
                start: 'top bottom',
                end: '+=100%',
                toggleActions: 'play none reverse none',
                scrub: 1,
            },
            y: -300,
            duration: 1,
        });
    });

    //emoji-faq-animation
    let emojiBlock = document.getElementById('abitur__emojis');

    function renderEmoji(col, classNameEmoji) {
        for (let i = 0; i < col; i++) {
            emojiBlock.innerHTML += `<div class="emoji ${classNameEmoji}" style="top:${returnRandomInt(window.innerHeight)}px;right:${returnRandomInt(window.innerWidth)}px;"></div>`;
        }
    }

    let k;

    function returnRandomInt(max) {
        k = Math.floor(Math.random() * max);
        return k;
    }

    renderEmoji(5, 'abitur__emoji-happy');
    renderEmoji(7, 'abitur__emoji-surprised');
    renderEmoji(4, 'abitur__emoji-sad');
});
