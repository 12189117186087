import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
//eslint-disable-next-line
import {Navigation, Pagination} from 'swiper/modules';

document.addEventListener('DOMContentLoaded', () => {
    new Swiper('.main__slider', {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 5500,
            disableOnInteraction: false,
        },
        modules: [Navigation, Pagination],
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    new Swiper('.questions__swiper', {
        slidesPerView: 'auto',
        spaceBetween: 24,
        loop: true,
        speed: 5000,
        autoplay: {
            enabled: true,
            delay: 0,
        },
    });

    new Swiper('.partners__swiper', {
        slidesPerView: 4,
        spaceBetween: 25,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        breakpoints: {
            340: {
                slidesPerView: 1,
            },
            600: {
                slidesPerView: 2,
            },
            991: {
                slidesPerView: 3,
            },
            1100: {
                slidesPerView: 4,
            },
        },
    });

    new Swiper('.resources__swiper', {
        slidesPerView: 3,
        spaceBetween: 24,
        autoplay: {
            delay: 5500,
            disableOnInteraction: false,
        },
        breakpoints: {
            340: {
                slidesPerView: 1,
            },
            600: {
                slidesPerView: 2,
            },
            991: {
                slidesPerView: 3,
            },
        },
    });

    new Swiper('.news-page-slider', {
        spaceBetween: 10,
        centeredSlides: true,
        autoplay: {
            delay: 5500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    new Swiper('.news-slider', {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 5500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    new Swiper('.news-slider', {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 5500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    new Swiper('.resources__big-slider', {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 5500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    new Swiper('.questions__small-swiper', {
        slidesPerView: 1,
        spaceBetween: 10,
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    new Swiper('.swiper-gallery', {
        spaceBetween: 10,
        centeredSlides: true,
        autoplay: {
            delay: 5500,
            disableOnInteraction: false,
        },
        modules: [Navigation, Pagination],
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    new Swiper('.news-page-slider', {
        spaceBetween: 10,
        centeredSlides: true,
        autoplay: {
            delay: 5500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });
});
