document.addEventListener('DOMContentLoaded', () => {
    let headerMenuBtnClose = document.querySelector('.burger-menu-close');
    let headerMenuBtn = document.querySelector('.burger-menu');
    let headerPopup = document.querySelector('.header__popup-wrapper');
    const overlay = document.querySelector('.overlay');
    let body = document.querySelector('body');

    function closeHeaderPopup() {
        headerPopup.classList.toggle('active');
    }

    function changeBodyStyle() {
        body.classList.toggle('active');
    }
    function changeBtnsStyle() {
        headerMenuBtnClose.classList.toggle('active');
        headerMenuBtn.classList.toggle('active');
    }

    headerMenuBtn.addEventListener('click', () => {
        changeBtnsStyle();
        changeBodyStyle();
        if (headerPopup.classList.contains('active')) {
            closeHeaderPopup();
        } else {
            closeHeaderPopup();
        }
    });

    headerMenuBtnClose.addEventListener('click', () => {
        changeBtnsStyle();
        closeHeaderPopup();
        changeBodyStyle();
    });

    overlay.addEventListener('click', () => {
        changeBtnsStyle();
        closeHeaderPopup();
        changeBodyStyle();
    });
});
