import {FormValidator} from './FormValidator';
import {FormSender} from './FormSender';
class QuestionForm extends FormSender {
    #formSelector = 'form[name="question"]';
    #btnSelector = '.btn';
    #btnTextSelector = '.message-success';
    #formErrorSelector = '.question-form__error';
    #requestUrl = '/api/v1/question';
    #errorText = 'Извините, произошла ошибка. Обновите страницу или попробуйте позже.';

    constructor() {
        super();

        this.form = document.querySelectorAll(this.#formSelector);

        this.form?.forEach((elem) => {
            this.btn = elem.querySelector(this.#btnSelector);
            this.formError = elem.querySelector(this.#formErrorSelector);
        });

        this.initSendEvent();
    }

    initSendEvent() {
        this.form?.forEach((elem) => {
            elem.addEventListener('submit', this.send.bind(this));
        });
    }

    send(e) {
        this.form.forEach((elem) => {
            this.btn = elem.querySelector(this.#btnSelector);
            this.formError = elem.querySelector(this.#formErrorSelector);

            e.preventDefault();
            this.#cleanError();

            const validationResult = new FormValidator().validate(elem);

            if (!validationResult.status) {
                this.#setError(validationResult.message);
                return;
            }

            const formData = new FormData(elem);

            this.formRequest(formData, this.#requestUrl)

                .then(() => {
                    this.#setSuccessText('Ваше сообщение отправлено! ' + '\n' + 'Мы скоро свяжемся с вами!', elem);
                    this.#clearForm();
                })
                .catch((err) => alert(err || this.#errorText))
                .finally(() => {});
        });
    }

    #cleanError() {
        this.formError.innerText = '';
    }

    #setError(message) {
        this.formError.innerText = message;
    }

    #clearForm() {
        this.form?.forEach((elem) => {
            const inputList = elem.querySelectorAll('input');
            const textareaList = elem.querySelectorAll('textarea');

            inputList.forEach((input) => (input.value = ''));
            textareaList.forEach((textarea) => (textarea.value = ''));
        });
    }

    #setSuccessText(text, form) {
        form.querySelector(this.#btnTextSelector).innerText = text;
    }
}

export {QuestionForm};
