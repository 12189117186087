class FormValidator {
    validate(form) {
        const formReqList = form.querySelectorAll('.required');
        let isValid = true;
        let errorMessage = '';
        let errorFields = [];

        formReqList.forEach((input) => {
            this.#formRemoveError(input.closest('.form__field'));
            const inputName = input.getAttribute('name');

            if (inputName === 'email' && this.#checkEmail(input)) {
                this.#formAddError(input.closest('.form__field'));
                isValid && (isValid = false);
                errorFields.push('Email');
            }

            if (inputName === 'phone' && this.#checkPhone(input)) {
                this.#formAddError(input.closest('.form__field'));
                isValid && (isValid = false);
                errorFields.push('Телефон');
            }

            if (inputName === 'password' && this.#checkPassword(input)) {
                this.#formAddError(input.closest('.form__field'));
                isValid && (isValid = false);
                errorFields.push('Пароль');
            }

            if (inputName === 'password-confirm') {
                this.secondPassword = document.querySelector('[name=password]');
                this.matchResult = this.#matchPwds(input, this.secondPassword);
            }

            if (inputName === 'password-confirm' && !this.matchResult) {
                this.#formAddError(input.closest('.form__field'));
                this.#formAddError(this.secondPassword.closest('.form__field'));
                isValid && (isValid = false);
                errorFields.push('Подтверждение пароля');
                delete this.matchResult;
            }
        });

        if (errorFields.length !== 0) {
            this.errorMessage = 'Некорректно заполнены: ' + errorFields.join(', ');
        }

        return {status: isValid, message: errorMessage};
    }

    #formAddError(input) {
        input.classList.add('_error');
    }

    #formRemoveError(input) {
        input.classList.remove('_error');
    }

    #checkEmail(input) {
        return !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(input.value);
    }

    #checkPhone(input) {
        return !/(^8|7|\+7)((\d{10})|(\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}))/.test(input.value);
    }

    #checkPassword(input) {
        return !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}/.test(input.value);
    }

    #matchPwds(pwd1, pwd2) {
        if (!pwd1 || !pwd2) return false;
        return pwd1.value === pwd2.value;
    }
}

export {FormValidator};
